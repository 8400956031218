import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import WalletGuard from '../components/WalletGuard';
import SubscriptionGuard from '../components/SubscriptionGuard';
import './Dashboard.css';

const BACKEND_URL = 'http://localhost:3002';

const formatLargeNumber = (numberString: string): string => {
  const number = parseFloat(numberString);
  if (isNaN(number)) return 'N/A';
  if (number >= 1e9) return (number / 1e9).toFixed(2) + 'B';
  if (number >= 1e6) return (number / 1e6).toFixed(2) + 'M';
  if (number >= 1e3) return (number / 1e3).toFixed(2) + 'K';
  return number.toFixed(2);
};

interface AccountCardProps {
  account: {
    publicKey: string;
    userWallet: string;
    paused: boolean;
    totalDeposited: string;
    totalSwapped: string;
  };
  tokenSymbol: string;
  tokenDecimals: number;
  index: number;
}

const AccountCard: React.FC<AccountCardProps> = ({ account, tokenSymbol, tokenDecimals, index }) => {
  const formatAmount = (amount: string | null): string => {
    if (amount == null) return 'N/A';
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return 'N/A';
    return formatLargeNumber(parsedAmount.toString());
  };

  return (
    <div className="card account-card">
      <h2>DCA Account #{index + 1}</h2>
      <div className="account-details">
        <div className="detail-row">
          <span className="detail-label">DCA Account:</span>
          <WalletAddress address={account?.publicKey ?? ''} />
        </div>
        <div className="detail-row">
          <span className="detail-label">User Wallet:</span>
          <WalletAddress address={account?.userWallet ?? ''} />
        </div>
        <div className="detail-row">
          <span className="detail-label">Status:</span>
          <span className="detail-value">{account?.paused ? 'Paused' : 'Active'}</span>
        </div>
        <div className="detail-row">
          <span className="detail-label">Total Deposited:</span>
          <span className="detail-value">{formatAmount(account?.totalDeposited)} {tokenSymbol}</span>
        </div>
        <div className="detail-row">
          <span className="detail-label">Total Swapped:</span>
          <span className="detail-value">{formatAmount(account?.totalSwapped)} {tokenSymbol}</span>
        </div>
      </div>
    </div>
  );
};

interface WalletAddressProps {
  address: string;
}

const WalletAddress: React.FC<WalletAddressProps> = ({ address }) => {
  const getExplorerUrl = (address: string): string => {
    return `https://solscan.io/account/${address}`;
  };

  if (!address) return <span>N/A</span>;

  return (
    <a href={getExplorerUrl(address)} target="_blank" rel="noopener noreferrer">
      {address.slice(0, 4)}...{address.slice(-4)}
    </a>
  );
};

interface PriceChartProps {
  data: { time: string; price: number }[];
  tokenSymbol: string;
  isOnCoinGecko: boolean;
}

const PriceChart: React.FC<PriceChartProps> = ({ data, tokenSymbol, isOnCoinGecko }) => {
  if (!isOnCoinGecko) {
    return (
      <div className="card price-chart-placeholder">
        <h2>{tokenSymbol} Price Chart</h2>
        <p>This token is not available on CoinGecko. Historical price data cannot be displayed.</p>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="card price-chart-placeholder">
        <h2>{tokenSymbol} Price Chart</h2>
        <p>No price data available for this token.</p>
      </div>
    );
  }

  return (
    <div className="card">
      <h2>{tokenSymbol} Price (Last 7 Days)</h2>
      <div style={{ width: '100%', height: 200 }}>
        {typeof window !== 'undefined' && (
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="price" stroke="#FF3C00" />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

const DCADashboard: React.FC = () => {
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [dcaAccounts, setDcaAccounts] = useState<any[]>([]);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const wallet = useWallet();

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [accountsResponse, tokenInfoResponse] = await Promise.all([
        axios.get(`${BACKEND_URL}/api/dca-accounts/${tokenAddress}`),
        axios.get(`${BACKEND_URL}/api/token-info/${tokenAddress}`)
      ]);
      
      console.log('Accounts data:', accountsResponse.data);
      console.log('Token info:', tokenInfoResponse.data);

      if (Array.isArray(accountsResponse.data)) {
        setDcaAccounts(accountsResponse.data);
      } else {
        console.error('Invalid accounts data format:', accountsResponse.data);
        setDcaAccounts([]);
      }

      if (tokenInfoResponse.data && typeof tokenInfoResponse.data === 'object') {
        setTokenInfo(tokenInfoResponse.data);
      } else {
        console.error('Invalid token info format:', tokenInfoResponse.data);
        setTokenInfo(null);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError("Failed to fetch data: " + ((err as any).response?.data?.error || (err as Error).message));
      setDcaAccounts([]);
      setTokenInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (tokenAddress) {
      fetchData();
    }
  };

  const activeAccounts = dcaAccounts ? dcaAccounts.filter(account => !account?.paused) : [];

  if (loading) {
    return <div className="loading-indicator">Loading...</div>;
  }

  return (
    <WalletGuard>
      <SubscriptionGuard>
        <div className="dca-dashboard">
          <h1>DCA Dashboard</h1>
          <WalletMultiButton />
          {wallet.connected ? (
            <>
              <form onSubmit={handleSubmit} className="token-input-form">
                <input
                  type="text"
                  value={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                  placeholder="Enter token contract address"
                  className="token-input"
                />
                <button type="submit" className="fetch-button">Submit</button>
              </form>
              {error && <div className="error-alert">{error}</div>}
              {tokenInfo && (
                <div className="token-info">
                  <p><strong>Name:</strong> {tokenInfo.name || 'Unknown'}</p>
                  <p><strong>Symbol:</strong> {tokenInfo.symbol || 'Unknown'}</p>
                  <p><strong>Address:</strong> <WalletAddress address={tokenInfo.address || ''} /></p>
                  <p><strong>Decimals:</strong> {tokenInfo.decimals != null ? tokenInfo.decimals : 'Unknown'}</p>
                  <p><strong>Current Price:</strong> ${tokenInfo.price != null ? tokenInfo.price.toFixed(8) : 'N/A'}</p>
                </div>
              )}
              <div className="content">
                {tokenInfo && tokenInfo.priceHistory && (
                  <PriceChart 
                    data={tokenInfo.priceHistory} 
                    tokenSymbol={tokenInfo.symbol || 'Token'} 
                    isOnCoinGecko={tokenInfo.isOnCoinGecko || false}
                  />
                )}
                <div className="dca-accounts-header">
                  <h2>DCA Accounts</h2>
                  <span className="active-accounts-count">Active Accounts: {activeAccounts.length}</span>
                </div>
                <div className="dca-accounts-container">
                  {dcaAccounts.length > 0 ? (
                    dcaAccounts.map((account, index) => (
                      account && Object.keys(account).length > 0 ? (
                        <AccountCard 
                          key={account.publicKey || index} 
                          account={account} 
                          tokenSymbol={tokenInfo ? (tokenInfo.symbol || 'Token') : 'Token'}
                          tokenDecimals={tokenInfo ? tokenInfo.decimals : 0}
                          index={index}
                        />
                      ) : null
                    ))
                  ) : (
                    <p>No DCA accounts found.</p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p>Please connect your wallet to use the DCA Dashboard.</p>
          )}
        </div>
      </SubscriptionGuard>
    </WalletGuard>
  );
};

export default DCADashboard;