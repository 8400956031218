import React, { useState } from 'react';

interface Offer {
  id: string;
  creator: string;
  token: string;
  amount: number;
  price: number;
  expiration: Date;
}

interface CreateOfferFormProps {
  onCreateOffer: (offer: Offer) => void;
}

const CreateOfferForm: React.FC<CreateOfferFormProps> = ({ onCreateOffer }) => {
  const [token, setToken] = useState('');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [expiration, setExpiration] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newOffer: Offer = {
      id: '', // This will be set in the parent component
      creator: 'Current User', // This should be replaced with the actual user's address
      token,
      amount: parseFloat(amount),
      price: parseFloat(price),
      expiration: new Date(expiration),
    };
    onCreateOffer(newOffer);
    // Reset form
    setToken('');
    setAmount('');
    setPrice('');
    setExpiration('');
  };

  return (
    <form onSubmit={handleSubmit} className="create-offer-form">
      <div className="form-group">
        <label htmlFor="token">Token:</label>
        <input
          type="text"
          id="token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="price">Price:</label>
        <input
          type="number"
          id="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="expiration">Expiration Date:</label>
        <input
          type="date"
          id="expiration"
          value={expiration}
          onChange={(e) => setExpiration(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="create-offer-btn">Create Offer</button>
    </form>
  );
};

export default CreateOfferForm;