import React from 'react';

interface Offer {
  id: string;
  creator: string;
  token: string;
  amount: number;
  price: number;
  expiration: Date;
}

interface OfferCardProps {
  offer: Offer;
}

const OfferCard: React.FC<OfferCardProps> = ({ offer }) => {
  return (
    <div className="offer-card">
      <h2>{offer.token} Offer</h2>
      <div className="offer-details">
        <p>Amount: {offer.amount} {offer.token}</p>
        <p>Price: ${offer.price}</p>
        <p>Expiration: {offer.expiration.toLocaleDateString()}</p>
      </div>
      <p className="creator">Creator: {offer.creator}</p>
      <button className="accept-offer-btn">Accept Offer</button>
    </div>
  );
};

export default OfferCard;