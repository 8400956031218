import React from 'react';
import { Link } from 'react-router-dom';

interface ToolCardProps {
  title: string;
  description: string;
  link: string;
}

const ToolCard: React.FC<ToolCardProps> = ({ title, description, link }) => (
  <Link to={link} className="tool-card">
    <h2>{title}</h2>
    <p>{description}</p>
  </Link>
);

export default ToolCard;