import React, { createContext, useState, useContext, useEffect, ReactNode, useCallback } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';

interface SubscriptionContextType {
  hasActiveSubscription: boolean;
  isChecking: boolean;
  isSubscribing: boolean;
  authenticationError: string | null;
  checkSubscription: () => Promise<void>;
  subscribeUser: () => Promise<void>;
  deleteSubscription: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const SUBSCRIPTION_COST = 0.001 * LAMPORTS_PER_SOL;
const SUBSCRIPTION_WALLET = new PublicKey('5Jgroz71FgSSBZP6xK6Z3oB4H2Zoa1iTJqyW4F1PkBTh');

export const SubscriptionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [authenticationError, setAuthenticationError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const { publicKey, signMessage, signTransaction, connected } = useWallet();
  const { connection } = useConnection();

  const authenticate = useCallback(async () => {
    if (!publicKey || !signMessage || !connected) {
      setAuthenticationError("Wallet not connected or unable to sign messages");
      return;
    }

    setIsAuthenticating(true);
    setAuthenticationError(null);
    try {
      const message = `Authenticate for Chain Reveal: ${Date.now()}`;
      const encodedMessage = new TextEncoder().encode(message);
      const signedMessage = await signMessage(encodedMessage);

      const response = await fetch(`${API_URL}/auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          signature: Buffer.from(signedMessage).toString('base64'),
          message,
          publicKey: publicKey.toBase58(),
        }),
      });

      if (response.ok) {
        const { token } = await response.json();
        setToken(token);
        localStorage.setItem('token', token);
        await checkSubscription();
      } else {
        console.error('Authentication failed');
        setToken(null);
        localStorage.removeItem('token');
        setAuthenticationError('Authentication failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setToken(null);
      localStorage.removeItem('token');
      setAuthenticationError('Failed to sign message. Please check your wallet and try again.');
    } finally {
      setIsAuthenticating(false);
    }
  }, [publicKey, signMessage, connected]);

  const checkSubscription = useCallback(async () => {
    if (!token) {
      setHasActiveSubscription(false);
      return;
    }

    setIsChecking(true);
    try {
      const response = await fetch(`${API_URL}/subscription`, {
        headers: { 'Authorization': token },
      });

      if (response.ok) {
        const { hasActiveSubscription } = await response.json();
        setHasActiveSubscription(hasActiveSubscription);
      } else {
        setHasActiveSubscription(false);
        if (response.status === 401) {
          setToken(null);
          localStorage.removeItem('token');
          setAuthenticationError('Session expired. Please reconnect your wallet.');
        }
      }
    } catch (error) {
      console.error('Failed to check subscription:', error);
      setHasActiveSubscription(false);
    } finally {
      setIsChecking(false);
    }
  }, [token]);

  const subscribeUser = async () => {
    if (!publicKey || !signTransaction || !token || isSubscribing) {
      return;
    }
  
    setIsSubscribing(true);
    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: SUBSCRIPTION_WALLET,
          lamports: SUBSCRIPTION_COST,
        })
      );
  
      const { blockhash } = await connection.getRecentBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
  
      const signedTransaction = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signedTransaction.serialize());
  
      console.log('Transaction sent:', signature);
  
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log('Transaction confirmed');
  
      const response = await fetch(`${API_URL}/subscription`, {
        method: 'POST',
        headers: { 
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ transactionSignature: signature }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        console.log('Subscription successful:', responseData);
        await checkSubscription();
      } else {
        console.error('Subscription failed:', responseData);
        throw new Error(`Failed to subscribe: ${responseData.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Subscription error:', error);
      if (error instanceof Error) {
        setAuthenticationError(`Failed to subscribe: ${error.message}`);
      } else {
        setAuthenticationError('Failed to subscribe: Unknown error');
      }
    } finally {
      setIsSubscribing(false);
    }
  };

  const deleteSubscription = async () => {
    if (!token) {
      throw new Error('Not authenticated');
    }

    try {
      const response = await fetch(`${API_URL}/subscription`, {
        method: 'DELETE',
        headers: { 'Authorization': token },
      });

      if (response.ok) {
        setHasActiveSubscription(false);
        console.log('Subscription deleted successfully');
      } else {
        throw new Error('Failed to delete subscription');
      }
    } catch (error) {
      console.error('Subscription deletion error:', error);
      setAuthenticationError('Failed to delete subscription. Please try again.');
    }
  };

  useEffect(() => {
    const checkAuthAndSubscription = async () => {
      if (connected) {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          setToken(storedToken);
          await checkSubscription();
        } else {
          await authenticate();
        }
      } else {
        setToken(null);
        setHasActiveSubscription(false);
        setAuthenticationError(null);
      }
    };

    checkAuthAndSubscription();
  }, [connected, authenticate, checkSubscription]);

  return (
    <SubscriptionContext.Provider value={{ 
      hasActiveSubscription, 
      isChecking, 
      isSubscribing,
      authenticationError,
      checkSubscription, 
      subscribeUser,
      deleteSubscription
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};