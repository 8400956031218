import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

interface WalletGuardProps {
  children: React.ReactNode;
}

const WalletGuard: React.FC<WalletGuardProps> = ({ children }) => {
  const { publicKey, connecting } = useWallet();

  if (connecting) {
    return <div className="wallet-connecting">Connecting to wallet...</div>;
  }

  if (!publicKey) {
    return (
      <div className="wallet-not-connected">
        <h2>Please connect your wallet</h2>
        <WalletMultiButton />
      </div>
    );
  }

  return <>{children}</>;
};

export default WalletGuard;