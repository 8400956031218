import React from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import { useWallet } from '@solana/wallet-adapter-react';

interface SubscriptionGuardProps {
  children: React.ReactNode;
}

const SubscriptionGuard: React.FC<SubscriptionGuardProps> = ({ children }) => {
  const { hasActiveSubscription, isChecking, isSubscribing, subscribeUser, deleteSubscription } = useSubscription();
  const { connected } = useWallet();

  if (!connected) {
    return (
      <div className="subscription-message">
        <h2>Wallet Not Connected</h2>
        <p>Please connect your wallet to access this content.</p>
      </div>
    );
  }

  if (isChecking) {
    return <div>Checking subscription status...</div>;
  }

  if (!hasActiveSubscription) {
    return (
      <div className="subscription-message">
        <h2>Subscription Required</h2>
        <p>You need an active subscription to view this content.</p>
        <button 
          className="subscribe-button" 
          onClick={subscribeUser} 
          disabled={isSubscribing}
        >
          {isSubscribing ? 'Subscribing...' : 'Subscribe Now'}
        </button>
      </div>
    );
  }

  return (
    <>
      {children}
      <button className="delete-subscription-button" onClick={deleteSubscription}>
        Delete Subscription (For Testing)
      </button>
    </>
  );
};

export default SubscriptionGuard;