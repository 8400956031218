import React, { useState } from 'react';
import WalletGuard from '../components/WalletGuard';
import OfferCard from '../components/OfferCard';
import CreateOfferForm from '../components/CreateOfferForm';

interface Offer {
  id: string;
  creator: string;
  token: string;
  amount: number;
  price: number;
  expiration: Date;
}

const mockOffers: Offer[] = [
  { id: '1', creator: 'User1', token: 'SOL', amount: 100, price: 20, expiration: new Date('2023-12-31') },
  { id: '2', creator: 'User2', token: 'USDC', amount: 1000, price: 1, expiration: new Date('2023-11-30') },
  { id: '3', creator: 'User3', token: 'RAY', amount: 500, price: 0.5, expiration: new Date('2023-12-15') },
];

const OTCMarketContent: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>(mockOffers);

  const handleCreateOffer = (newOffer: Offer) => {
    setOffers([...offers, { ...newOffer, id: Date.now().toString() }]);
  };

  return (
    <div className="otc-market-container">
      <h1>OTC Market</h1>
      <div className="offers-grid">
        {offers.map((offer) => (
          <OfferCard key={offer.id} offer={offer} />
        ))}
      </div>
      <div className="create-offer-section">
        <h2>Create New Offer</h2>
        <CreateOfferForm onCreateOffer={handleCreateOffer} />
      </div>
    </div>
  );
};

const OTCMarket: React.FC = () => (
  <WalletGuard>
    <OTCMarketContent />
  </WalletGuard>
);

export default OTCMarket;