import React from 'react';
import { Link } from 'react-router-dom';
import WalletGuard from '../components/WalletGuard';
import ToolCard from '../components/ToolCard';

const HomeContent: React.FC = () => (
  <div className="home-container">
    <h1>Welcome</h1>
    <div className="tools-grid">
      <ToolCard 
        title="OTC Markets" 
        description="Your one-stop shop for OTC trading" 
        link="/otc-market" 
      />
      <ToolCard 
        title="Tools" 
        description="Some tools to help you with your Solana trading" 
        link="/pnl-calculator" 
      />
      <ToolCard 
        title="Documentation" 
        description="Learn more about our platform" 
        link="/pnl-calculator" 
      />
    </div>
  </div>
);

const Home: React.FC = () => (
  <WalletGuard>
    <HomeContent />
  </WalletGuard>
);

export default Home;