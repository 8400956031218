import React from 'react';
import WalletGuard from '../components/WalletGuard';
import SubscriptionGuard from '../components/SubscriptionGuard';

const PnLCalculator: React.FC = () => {
  return (
    <WalletGuard>
      <SubscriptionGuard>
        <div>PnL Calculator (to be implemented)</div>
      </SubscriptionGuard>
    </WalletGuard>
  );
};

export default PnLCalculator;