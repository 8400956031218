import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useSubscription } from '../contexts/SubscriptionContext';

// Replace this with your actual Helius RPC endpoint
const HELIUS_RPC_ENDPOINT = process.env.REACT_APP_HELIUS_RPC_ENDPOINT || '';

const Header: React.FC = () => {
  const { hasActiveSubscription, isChecking } = useSubscription();
  const { publicKey } = useWallet();
  const [balance, setBalance] = useState<number | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchBalance = async () => {
      if (!publicKey) {
        setBalance(null);
        return;
      }

      const connection = new Connection(HELIUS_RPC_ENDPOINT, 'confirmed');

      try {
        const balance = await connection.getBalance(publicKey);
        if (isMounted) {
          setBalance(balance / LAMPORTS_PER_SOL);
        }
      } catch (e) {
        console.error('Failed to fetch balance:', e);
        if (isMounted) {
          setBalance(null);
        }
      }
    };

    fetchBalance();
    const intervalId = setInterval(fetchBalance, 30000); // Update every 30 seconds

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [publicKey]);

  return (
    <header className="header">
      <h1>MakerSol</h1>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/dca-dashboard">Markets</Link>
      </nav>
      <div className="wallet-info">
      <span className={`subscription-status ${
          isChecking ? 'checking' : (hasActiveSubscription ? 'subscribed' : 'not-subscribed')
        }`}>
          {isChecking ? 'Checking...' : (hasActiveSubscription ? 'Subscribed' : 'Not Subscribed')}
        </span>
        {balance !== null ? (
          <span className="sol-balance">{balance.toFixed(2)} SOL</span>
        ) : publicKey ? (
          <span className="sol-balance">Loading...</span>
        ) : null}
        
        <WalletMultiButton />
      </div>
    </header>
  );
};

export default Header;