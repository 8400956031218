import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WalletContextProvider } from './contexts/WalletContextProvider';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import Header from './components/Header';
import Home from './pages/Home';
import DCADashboard from './pages/DCADashboard';
import PnLCalculator from './pages/PnLCalculator';
import LiquidityManager from './pages/LiquidityManager';
import OTCMarket from './pages/OTCMarket';
import './App.css';

require('@solana/wallet-adapter-react-ui/styles.css');

const App: React.FC = () => {
  return (
    <WalletContextProvider>
      <SubscriptionProvider>
        <Router>
          <div className="App">
            <Header />
            <main className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dca-dashboard" element={<DCADashboard />} />
                <Route path="/pnl-calculator" element={<PnLCalculator />} />
                <Route path="/liquidity-manager" element={<LiquidityManager />} />
                <Route path="/otc-market" element={<OTCMarket />} />
              </Routes>
            </main>
          </div>
        </Router>
      </SubscriptionProvider>
    </WalletContextProvider>
  );
};

export default App;